/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { connect } from 'react-redux'

import screenTypesMap from '../../layout/_parts/screenTypesMap'

import EmailComponent from './_parts/emailComponent'
import PassComponent from './_parts/passComponent'
import ResetPasswordComponent from './_parts/resetPasswordComponent'
import ResetRequestSentComponent from './_parts/resetRequestSentComponent'
import ResetNotAllowedComponent from './_parts/resetNotAllowedComponent'
import AuthFormTitleComponent from './_parts/authFormTitleComponent'
import NewPassComponent from './_parts/newPasswordComponent'
import SplasScreenComponent from './_parts/splashScreenComponent'
import UserNotEnabledComponent from './_parts/userNotEnabledComponent'

import * as actionCreators from '../../store/actions'
import authPagesMap from './_parts/authPagesMap'
import './styles.scss'

const classNames = require('classnames')
const queryString = require('query-string')

export class LoginPanel extends React.Component {
  componentDidMount = async () => {
    const { setAuthProcessPage } = this.props
    if (typeof window !== 'undefined') {
      const qs = queryString.parse(window.location.search)
      if (qs.token && qs.email) {
        setAuthProcessPage(authPagesMap.newpassword)
      }
      if (qs.waiting_for_approval) {
        setAuthProcessPage(authPagesMap.userNotEnabled)
      }
    }
  }

  render() {
    const {
      device,
      authProcess,
      setUserEmailErrorVisibile,
      checkIfEmailExistsAndUpdateStore,
      tryToLogInAndUpdateStore,
      checkIfUserCanRequestPasswordReset,
      texts,
      cobranding,
      setPasswordErrorVisibile,
      processPasswordResetRequest,
      setAuthProcessPage,
      setRepeatedPasswordErrorVisibile,
      setNewPasswordErrorVisibile,
      processResetPassword,
    } = this.props

    function handleShowPasswordPage() {
      if (!authProcess.userEmailValidation.isValid) {
        setUserEmailErrorVisibile(true)
      } else {
        checkIfEmailExistsAndUpdateStore(authProcess.userEmail)
      }
    }

    function handleExecuteLogin() {
      if (authProcess.passwordValidation.isEmpty) {
        setPasswordErrorVisibile(true)
      } else {
        tryToLogInAndUpdateStore(authProcess.userEmail, authProcess.password)
      }
    }

    function handleForgotPasswordButtonClick() {
      checkIfUserCanRequestPasswordReset(authProcess.userEmail)
    }

    function handleResetPassword() {
      processPasswordResetRequest(authProcess.userEmail)
    }

    function handleSaveNewPassword() {
      if (authProcess.resetPasswordValidation.isNewPasswordEmpty) {
        setNewPasswordErrorVisibile(true)
      } else if (
        authProcess.resetPasswordValidation.isRepeatedPasswordEmpty ||
        !authProcess.resetPasswordValidation.areEquals
      ) {
        setRepeatedPasswordErrorVisibile(true)
      } else {
        const qs = queryString.parse(window.location.search)
        if (qs.token && qs.email) {
          processResetPassword(
            qs.email,
            qs.token,
            authProcess.newPassword,
            authProcess.repeatedPassword,
            authPagesMap.useremail
          )
        }
      }
    }

    function handleGoToPasswordStep() {
      setAuthProcessPage(authPagesMap.password)
    }

    function handleOauthRedirect() {
      // TODO move this function in a proper utils file
      function generateStateToken(length = 16) {
        const array = new Uint8Array(length)
        window.crypto.getRandomValues(array)
        return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('')
      }
      const stateToken = generateStateToken()
      sessionStorage.setItem('oidcState', stateToken)

      // const state = TODO generate a proper string to prevent CSRF
      // TODO: store state in local storage
      const params = {
        redirect_uri: process.env.oidcRedirectUri,
        state: stateToken,
      }
      window.location.replace(`${process.env.apiUrl}oauth/redirect?${queryString.stringify(params)}`)
    }

    const renderSplasScreen =
      device.screenType !== screenTypesMap.desk &&
      device.screenType !== screenTypesMap.iPadH &&
      !authProcess.splashScreenUsed

    const touchBkground = authProcess.splashScreenUsed
      ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(to bottom, rgba(0, 0, 0, 0) 52%, #000000), url(${cobranding.auth_ipad_bg_url})`
      : `url(${cobranding.auth_ipad_bg_url})`

    const oidcFlow = process.env.oidcFlow !== undefined ? JSON.parse(process.env.oidcFlow) : false

    return (
      <div>
        <div
          className={classNames({
            login_panel: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          })}
        >
          <div
            className={classNames({
              login_background_container: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              splash_screen: !authProcess.splashScreenUsed,
            })}
            style={{
              backgroundImage:
                device.screenType === screenTypesMap.desk ? `url(${cobranding.auth_desk_bg_url})` : touchBkground,
            }}
          />
          {renderSplasScreen && <SplasScreenComponent />}
          {!renderSplasScreen && (
            <div
              className={classNames({
                login_form_container: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              <div
                className={classNames({
                  login_form_page: true,
                  slide_top: device.screenType === screenTypesMap.iPadV,
                })}
              >
                {(device.screenType === screenTypesMap.desk || device.screenType === screenTypesMap.iPadH) && (
                  <div
                    className={classNames({
                      login_form_logo_container: true,
                    })}
                  >
                    {JSON.parse(process.env.cobrandingMode) && (
                      <div
                        className={classNames({
                          login_form_cobranding_logo_container: true,
                        })}
                      >
                        <img src={cobranding.small_client_logo_url} alt="client Logo" />
                      </div>
                    )}
                    <img
                      className={classNames({
                        login_form_valueable_logo: true,
                      })}
                      src={cobranding.medium_valueable_logo}
                      alt="ValueAble Logo"
                    />
                  </div>
                )}
                {device.screenType !== screenTypesMap.desk && device.screenType !== screenTypesMap.iPadH && (
                  <div
                    className={classNames({
                      login_form_touch_logo_container: true,
                    })}
                  >
                    {JSON.parse(process.env.cobrandingMode) && (
                      <img
                        className={classNames({
                          login_form_client_logo: true,
                        })}
                        src={cobranding.big_client_logo_url}
                        alt="client Logo"
                      />
                    )}
                    <img
                      className={classNames({
                        login_form_valueable_logo: true,
                      })}
                      src={cobranding.big_valueable_logo}
                      alt="ValueAble Logo"
                    />
                  </div>
                )}
                <form
                  className={classNames({
                    login_form_outer_container: true,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                >
                  <div className={classNames({ login_form_inner_container: true })}>
                    {!oidcFlow && (
                      <>
                        <AuthFormTitleComponent />

                        {(authProcess.page === authPagesMap.useremail ||
                          authProcess.page === authPagesMap.password) && (
                          <>
                            <EmailComponent handleShowPasswordPage={handleShowPasswordPage} />
                            <PassComponent handleExecuteLogin={handleExecuteLogin} />
                          </>
                        )}
                        {authProcess.page === authPagesMap.resetpass && <ResetPasswordComponent />}
                        {authProcess.page === authPagesMap.resetnotallowed && <ResetNotAllowedComponent />}
                        {authProcess.page === authPagesMap.resetrequestsent && <ResetRequestSentComponent />}
                        {authProcess.page === authPagesMap.newpassword && <NewPassComponent />}
                        <button
                          type="button"
                          className={classNames({
                            lg_btn: true,
                            secondary_btn: true,
                            float_right_btn: device.screenType === screenTypesMap.desk,
                            btn_300:
                              device.screenType === screenTypesMap.iPadV ||
                              device.screenType === screenTypesMap.iPhoneXR ||
                              device.screenType === screenTypesMap.iPhoneSE,
                            touch_btn: device.screenType !== screenTypesMap.desk,
                            theme_color: true,
                          })}
                          onClick={(() => {
                            switch (authProcess.page) {
                              case authPagesMap.useremail:
                                return handleShowPasswordPage
                              case authPagesMap.password:
                                return handleExecuteLogin
                              case authPagesMap.resetpass:
                                return handleResetPassword
                              case authPagesMap.resetnotallowed:
                                return handleGoToPasswordStep
                              case authPagesMap.resetrequestsent:
                                return handleGoToPasswordStep
                              case authPagesMap.newpassword:
                                return handleSaveNewPassword
                              default:
                                return null
                            }
                          })()}
                        >
                          {(() => {
                            switch (authProcess.page) {
                              case authPagesMap.useremail:
                                return <span>{texts.auth_next}</span>
                              case authPagesMap.password:
                                return <span>{texts.auth_connect}</span>
                              case authPagesMap.resetpass:
                                return <span>{texts.auth_send_email}</span>
                              case authPagesMap.resetnotallowed:
                                return <span>{texts.auth_ok}</span>
                              case authPagesMap.resetrequestsent:
                                return <span>{texts.auth_ok}</span>
                              case authPagesMap.newpassword:
                                return <span>{texts.auth_save_new_password}</span>
                              default:
                                return null
                            }
                          })()}
                        </button>
                      </>
                    )}
                    {oidcFlow && (
                      <>
                        <button
                          onClick={handleOauthRedirect}
                          type="button"
                          className="lg_btn secondary_btn theme_color"
                        >
                          Login
                        </button>

                        {authProcess.page === authPagesMap.userNotEnabled && <UserNotEnabledComponent />}
                      </>
                    )}
                  </div>
                </form>
                {authProcess.page === authPagesMap.password && (
                  <div
                    className={classNames({
                      login_form_forgot_password_container: true,
                      isSafari: device.isSafari,
                      isFirefox: device.isFirefox,
                      desk: device.screenType === screenTypesMap.desk,
                      ipadh: device.screenType === screenTypesMap.iPadH,
                      ipadv: device.screenType === screenTypesMap.iPadV,
                      iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                      iphonese: device.screenType === screenTypesMap.iPhoneSE,
                    })}
                  >
                    <button
                      type="button"
                      onClick={handleForgotPasswordButtonClick}
                      className={classNames({
                        styleless_btn: true,
                      })}
                    >
                      <span>{texts.auth_forgot_password}</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authProcess: state.authentication.authProcess,
    texts: state.texts.values,
    cobranding: state.environment.cobranding,
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(LoginPanel)
