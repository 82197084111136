import {
  isValidEmail,
  login,
  loadUserFromLocalStorage,
  loadTokenFromLocalStorage,
  getUserRequestsObjectFromStorage,
  updateUserRequestsObjectInStorage,
  requestPasswordReset,
  resetPassword,
  verifyIfDateIsExpired,
  exchangeOauthCode,
  updateUserAttributes,
} from '../../../../va-corejs-v3/actions/authentication'
import storage from '../../../../va-corejs-v3/storage'
import authPagesMap from '../../../components/login/_parts/authPagesMap'
import msgBoxMessageMap from '../../../../va-corejs-v3/utils/msgBoxMessagesMap'

export function updateLogout() {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_MSGBOX',
      msgboxMessage: msgBoxMessageMap.logout,
      data: {},
    })
  }
}

export function setAuthProcessPage(page) {
  return dispatch => {
    dispatch({
      type: 'SET_AUTH_PROCESS_PAGE',
      page,
    })
  }
}

export function setUserNotEnabled(status) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_USER_NOT_ENABLED',
      userNotEnabled: status,
    })
  }
}

/* SPLASH SCREEN ACTIONS */
export function setSplashScreenUsedStatus(splashScreenUsed) {
  return dispatch => {
    dispatch({
      type: 'SET_SPLASH_SCREEN_USED_STATUS',
      splashScreenUsed,
    })
  }
}

/* USER EMAIL ACTIONS */

export function updateUserEmail(sender) {
  const userEmail = sender.target.value
  const isEmpty = userEmail.length === 0
  const isValid = isValidEmail(userEmail)
  return dispatch => {
    dispatch({
      type: 'UPDATE_USEREMAIL',
      userEmail,
      isEmpty,
      isValid,
    })
  }
}

export function checkIfEmailExistsAndUpdateStore() {
  return async dispatch => {
    // const exists = await checkIfEmailExists(sender)
    const exists = true
    const page = exists ? authPagesMap.password : authPagesMap.useremail
    const showUserEmailError = !exists
    dispatch({
      type: 'UPDATE_USER_EMAIL_STATUS',
      exists,
      page,
      showUserEmailError,
    })
  }
}

export function setPasswordErrorVisibile(isVisible) {
  return dispatch => {
    dispatch({
      type: 'SET_PASSWORD_ERROR_VISIBLE',
      showPasswordError: isVisible,
    })
  }
}

export function setNewPasswordErrorVisibile(isVisible) {
  return dispatch => {
    dispatch({
      type: 'SET_NEW_PASSWORD_ERROR_VISIBLE',
      showNewPasswordError: isVisible,
    })
  }
}

export function setRepeatedPasswordErrorVisibile(isVisible) {
  return dispatch => {
    dispatch({
      type: 'SET_REPEATED_PASSWORD_ERROR_VISIBLE',
      showRepeatedPasswordError: isVisible,
    })
  }
}

/* PASSWORD ACTIONS */

export function updatePassword(sender) {
  const password = sender.target.value
  const isEmpty = password.length === 0

  return dispatch => {
    dispatch({
      type: 'UPDATE_PASSWORD',
      password,
      isEmpty,
    })
  }
}

export function updateNewPasswords(newPassword, repeatedPassword) {
  const isNewPasswordEmpty = newPassword.length === 0
  const isRepeatedPasswordEmpty = repeatedPassword.length === 0

  const areEquals = newPassword === repeatedPassword

  return dispatch => {
    dispatch({
      type: 'UPDATE_NEW_PASSWORDS',
      newPassword,
      repeatedPassword,
      isNewPasswordEmpty,
      isRepeatedPasswordEmpty,
      areEquals,
    })
  }
}

export function tryToLogInAndUpdateStore(email, password) {
  return async dispatch => {
    const loginResponse = await login(email, password)
    const { user, token } = loginResponse
    const isLogged = user !== undefined && !(user === false)
    const showPasswordError = !isLogged
    const exists = isLogged
    dispatch({
      type: 'UPDATE_LOGIN_STATUS',
      isLogged,
      token,
      user,
      exists,
      showPasswordError,
    })
  }
}

export function exchangeOauthCodeAndUpdateStore(code, state, redirectUri) {
  return async dispatch => {
    let user = null
    let token = null
    let userNotEnabled = null

    const storedState = sessionStorage.getItem('oidcState')

    // CSRF state token check
    // eslint-disable-next-line eqeqeq
    if (storedState == state) {
      const loginResponse = await exchangeOauthCode(code, redirectUri)
      // eslint-disable-next-line prefer-destructuring
      token = loginResponse.token
      // eslint-disable-next-line prefer-destructuring
      user = loginResponse.user
      // eslint-disable-next-line prefer-destructuring
      userNotEnabled = loginResponse.userNotEnabled
    }

    const isLogged = !!user
    const showPasswordError = !isLogged
    const exists = isLogged
    dispatch({
      type: 'UPDATE_LOGIN_STATUS',
      isLogged,
      token,
      user,
      exists,
      showPasswordError,
    })
    dispatch({
      type: 'UPDATE_USER_NOT_ENABLED',
      userNotEnabled,
    })
  }
}

export function setUserEmailErrorVisibile(isVisible) {
  return dispatch => {
    dispatch({
      type: 'SET_USER_EMAIL_ERROR_VISIBLE',
      showUserEmailError: isVisible,
    })
  }
}

export function updateUser() {
  return async dispatch => {
    let user = await loadUserFromLocalStorage()
    const token = await loadTokenFromLocalStorage()
    let isLogged = true
    let showPasswordError = false
    let exists = true
    if (user === false) {
      isLogged = false
      showPasswordError = false
      exists = false
      user = {}
    }
    dispatch({
      type: 'UPDATE_LOGIN_STATUS',
      isLogged,
      token,
      user,
      exists,
      showPasswordError,
    })
  }
}

export function logout() {
  return async dispatch => {
    await storage.empty('user')
    await storage.empty('access_token')

    dispatch({
      type: 'UPDATE_PASSWORD',
      password: '',
      isEmpty: true,
    })

    dispatch({
      type: 'UPDATE_USEREMAIL',
      userEmail: '',
      isEmpty: true,
      isValid: false,
    })

    dispatch({
      type: 'UPDATE_USER_EMAIL_STATUS',
      exists: false,
      page: 0,
      showUserEmailError: false,
    })
  }
}

/* RESET PASSWORD ACTIONS */

export function checkIfUserCanRequestPasswordReset(email) {
  return async dispatch => {
    const userRequestsObj = await getUserRequestsObjectFromStorage(email)

    let availablePasswordResetRequestsNumber = 0

    const maxRequestsAvailable = parseInt(process.env.maxPassResetReqPerHour, 10)

    if (!userRequestsObj) {
      availablePasswordResetRequestsNumber = maxRequestsAvailable
    } else {
      const { creationDate } = userRequestsObj
      const isExpired = verifyIfDateIsExpired(new Date(creationDate))

      availablePasswordResetRequestsNumber = isExpired
        ? maxRequestsAvailable
        : maxRequestsAvailable - userRequestsObj.requestsMade
    }
    const page = availablePasswordResetRequestsNumber > 0 ? authPagesMap.resetpass : authPagesMap.resetnotallowed
    dispatch({
      type: 'UPDATE_PASS_RESET_REQUESTS_AVAILABILITY_AND_PAGE',
      availablePasswordResetRequestsNumber,
      page,
    })
  }
}

export function processPasswordResetRequest(email) {
  return async dispatch => {
    const result = await requestPasswordReset(email, process.env.siteUrl)

    if (result) {
      const userRequestsObj = await updateUserRequestsObjectInStorage(email)

      const availablePasswordResetRequestsNumber =
        parseInt(process.env.maxPassResetReqPerHour, 10) - userRequestsObj.requestsMade

      const page = authPagesMap.resetrequestsent

      dispatch({
        type: 'UPDATE_PASS_RESET_REQUESTS_STATUS',
        passwordResetRequests: userRequestsObj,
        availablePasswordResetRequestsNumber,
        page,
      })
    }
  }
}

export function processResetPassword(email, token, password, passwordConfirmation, page) {
  return async dispatch => {
    const result = await resetPassword(email, token, password, passwordConfirmation)

    if (result.status === 200) {
      dispatch({
        type: 'SET_AUTH_PROCESS_PAGE',
        page,
      })
    } else {
      let errorFromServer = result.data.message
      const { errors } = result.data
      if (errors && Object.keys(errors).length > 0) {
        const firstLineErrors = errors[Object.keys(errors)[0]]
        errorFromServer = firstLineErrors.length > 0 ? firstLineErrors[0] : ''
      }

      dispatch({
        type: 'UPDATE_RESET_PASSWORD_SERVER_RESPONSE',
        errorFromServer,
      })
    }
  }
}

export function updateUserLastScoring(scoringId) {
  return async dispatch => {
    if (scoringId) {
      await updateUserAttributes({ last_displayed_product_id: scoringId })

      dispatch({
        type: 'UPDATE_USER_LAST_SCORING',
        scoringId,
      })
    }
  }
}
